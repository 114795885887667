<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <upload-file-card :title="titleUpload"> </upload-file-card>
    </div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-cyan">
          <div class="card-icon">
            <md-icon>map</md-icon>
          </div>
          <h4 class="title">{{ title | capitalize }}</h4>
        </md-card-header>
        <md-card-content>
          <open-layer-loader></open-layer-loader>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import { UploadFileCard, OpenLayerLoader } from "@/components";

export default {
  components: {
    "upload-file-card": UploadFileCard,
    "open-layer-loader": OpenLayerLoader
  },
  data() {
    return {
      title: this.$route.params.title,
      titleUpload: null
    };
  },
  mounted() {
    this.titleUpload = `do mapa ${this.title}`;
  }
};
</script>
<style>
#map {
  height: calc(100vh - 70px);
}
</style>
